import React from 'react';
import { motion } from 'framer-motion'; // For subtle animations

function PageTitle() {
  return (
      <div className="page-title-container">
        <motion.div
            className="page-title-content"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <img
              src="/images/aicons.jpg"
              alt="AIcons Logo"
              className="page-title-icon"
              loading="lazy"
          />
          <h1 className="page-title">aiatlas.app</h1>
        </motion.div>
        <motion.p
            className="page-caption"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut', delay: 0.3 }}
        >
          Chart your course through the best in generative AI.
        </motion.p>
      </div>
  );
}

export default PageTitle;
