import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Firebase config
import './App.css'; // Custom styles
import { HiOutlineAdjustments } from "react-icons/hi";
import PageTitle from './PageTitle';
import ImageWithPlaceholder from "./ImageWithPlaceholder";

// Upload image to Firebase Storage with cache control

// Main component
function AICon() {
  const [icons, setIcons] = useState([]); // Cache all icons locally
  const [selectedTag, setSelectedTag] = useState('All');
  const [uniqueTags, setUniqueTags] = useState([]);
  const [showAddIconForm, setShowAddIconForm] = useState(false);
  const [isTrending, setIsTrending] = useState(false); // Track if "Trending" is selected
  const [error, setError] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false); // State to manage filter visibility
  const [activePanel, setActivePanel] = useState(null); // Controls which panel is open
  // Toggle Add Icon form visibility
  const toggleAddIconForm = () => {
    setShowAddIconForm(false);
  };

  // Toggle filter side panel visibility
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevState) => !prevState);
  };
  // Fetch icons from Firestore only once and cache them locally
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'icons'), (snapshot) => {
      const fetchedIcons = snapshot.docs.map((doc) => ({
        id: doc.id, ...doc.data(),
      }));
      setIcons(fetchedIcons); // Cache icons

      const allTags = new Set();
      fetchedIcons.forEach((icon) => icon.tags.forEach((tag) => allTags.add(tag)));
      setUniqueTags(['All', ...Array.from(allTags)]);
    }, (error) => {
      console.error('Error fetching Firestore data:', error);
      setError('Failed to fetch icons. Please try again.');
    });

    return () => unsubscribe();
  }, []);

  // Handle adding a new icon
  const handleAddIcon = (newIcon) => {
    setIcons([...icons, {...newIcon, id: icons.length + 1}]);
    toggleAddIconForm();
  };

  // Close the filter panel when clicked outside
  const handleClickAway = (e) => {
    // Check if the click happened outside the filter panel
    if (isFilterVisible && !e.target.closest('.filter-side-panel') && !e.target.closest('.filter-icon')) {
      setIsFilterVisible(false); // Close the filter panel
    }
  };
  // Memoize the filtered and sorted icons based on whether Trending or a tag is selected
  const filteredIcons = useMemo(() => {
    if (isTrending) {
      const oneHourAgo = new Date().getTime() - 24 * 60 * 60 * 1000;
      return icons
          .filter((icon) => icon.recentClicks
              > 0
              && icon.lastClickedAt
              && icon.lastClickedAt.toMillis()
              >= oneHourAgo)
          .sort((a, b) => b.recentClicks - a.recentClicks); // Sort by recent clicks when trending is active
    }
    return icons
        .filter((icon) => selectedTag === 'All' || icon.tags.includes(selectedTag))
        .sort((a, b) => b.clicks - a.clicks); // Sort by clicks for other filters
  }, [icons, selectedTag, isTrending]);

  // Toggle panel function to open/close panels
  const togglePanel = (panel) => {
    setActivePanel((prevPanel) => (prevPanel === panel ? null : panel)); // Close if clicked again
  };

  const handleMouseLeave = () => {
    setActivePanel(null); // Close panel when mouse leaves
  };

  // Close the filter panel when clicking outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [isFilterVisible]);

  return (<div className="app-wrapper">
        <div id="root"></div>
        <PageTitle/>

        {/* Filter Button */}
        <HiOutlineAdjustments className="filter-icon" onClick={toggleFilterVisibility}/>

        {/* Navigation Bar for About and Terms */}
        <div className="navbar">
          <a
              href="#"
              onClick={() => togglePanel('about')}a
              className={activePanel === 'about' ? 'active' : ''}
          >
            About
          </a>
          <a
              href="#"
              onClick={() => togglePanel('terms')}
              className={activePanel === 'terms' ? 'active' : ''}
          >
            Terms
          </a>
        </div>
        {/* Filter Side Panel (From the Left) */}
        <div className={`filter-side-panel ${isFilterVisible ? 'open' : ''}`}>
          <h2>Filter by Tags</h2>
          <button
              onClick={() => {
                setIsTrending(true);
                setSelectedTag(null);
                toggleFilterVisibility();
              }}
              className={isTrending ? 'active' : ''}
          >
            🔥 Trending ~ past 24hrs
          </button>

          {uniqueTags.map((tag, index) => (
              <button
                  key={index}
                  onClick={() => {
                    setSelectedTag(tag);
                    setIsTrending(false);
                    toggleFilterVisibility();
                  }}
                  className={selectedTag === tag && !isTrending ? 'active' : ''}
              >
                {tag}
              </button>
          ))}
        </div>

        {/* Error Handling */}
        {error ? (<p className="error-message">{error}</p>) : (<div className="content-area">
              <IconWall icons={filteredIcons} onAddClick={toggleAddIconForm}/>
            </div>)}

        {/*/!* Add Icon Form *!/*/}
        {/*{showAddIconForm && (<AddIconForm*/}
        {/*        onSubmit={handleAddIcon}*/}
        {/*        onCancel={toggleAddIconForm}*/}
        {/*        uniqueTags={uniqueTags}*/}
        {/*    />)}*/}

        {/* Side Panel for About and Terms */}
        <div
            className={`side-panel ${activePanel ? 'open' : ''}`}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={() => setActivePanel(activePanel)}
        >
          {activePanel === 'about' && (<div className="panel-content about">
                <h2>About aiatlas.app</h2>
                <p>
                  aiatlas.app is a comprehensive directory of free AI tools, allowing users to
                  discover, explore, and contribute links for AI models, startups, and more. It
                  simplifies access to valuable AI resources, making them easy to navigate and
                  visually engaging.
                </p>
                <p>
                  Icons are ranked by popularity, with the most clicked tools shown at the top-left.
                  More clicks push tools higher.
                </p>
                <p>
                  Rankings update in real-time, helping users discover trending and popular AI tools
                  quickly.
                </p>
              </div>)}

          {activePanel === 'terms' && (<div className="panel-content terms">
                <h2>Terms and Conditions</h2>
                <p>
                  By using aiatlas.app, you agree to the following terms and conditions. The content
                  and services provided are for informational purposes and do not guarantee results.
                  Please use the platform responsibly.
                </p>
                <h3>1. Acceptance of Terms</h3>
                <p>
                  By accessing and using aiatlas.app, you agree to comply with and be bound by the
                  following terms and conditions. If you do not agree to these terms, please do not
                  use the website.
                </p>
                <h3>2. Use of the Website</h3>
                <p>
                  aiatlas.app is a platform that provides users with access to a curated collection
                  of AI-related icons, models, and resources. You may browse and use the icons and
                  resources available on the website for personal or non-commercial purposes. Users
                  are responsible for ensuring that their use of any tools or icons complies with
                  the licensing and usage terms provided by third-party contributors or services
                  linked from our site.
                </p>
                <h3>3. Account Creation</h3>
                <p>
                  To contribute new icons or tools, or to participate in certain interactive
                  features, you may need to create an account. You are responsible for maintaining
                  the security of your account and for any actions taken under your account.
                </p>
                <h3>4. User-Generated Content</h3>
                <p>
                  Users may submit icons and tools to the platform. By submitting content, you grant
                  aiatlas.app a non-exclusive, royalty-free license to display, modify, and
                  distribute your content as part of the platform. You affirm that you own or have
                  the necessary rights to any content you submit to the website and that your
                  submission does not infringe on any third-party rights.
                </p>
                <h3>5. Intellectual Property</h3>
                <p>
                  All content on the website, including but not limited to icons, images, text, and
                  software, is the property of aiatlas.app or its content suppliers and is
                  protected by intellectual property laws. You may not reproduce, distribute, or
                  modify any content from the website without proper authorization.
                </p>
                <h3>6. Ranking and Popularity</h3>
                <p>
                  The ranking of tools and icons on aiatlas.app is based on user interaction,
                  including clicks and views. These rankings are automatically updated and are
                  subject to change based on user behavior. The platform reserves the right to
                  adjust the ranking algorithm at any time to improve user experience.
                </p>
                <h3>7. Disclaimer of Warranties</h3>
                <p>
                  The content and services on aiatlas.app are provided "as is." We make no
                  warranties or representations, express or implied, about the accuracy,
                  completeness, or availability of the content on the website. aiatlas.app
                  disclaims all liability for errors, interruptions, or issues in website
                  performance.
                </p>
                <h3>8. Limitation of Liability</h3>
                <p>
                  aiatlas.app shall not be liable for any direct, indirect, incidental, or
                  consequential damages arising from the use or inability to use the website or its
                  content. You acknowledge that the use of any third-party tools or icons linked
                  from our website is at your own risk. aiatlas.app does not control, monitor, or
                  endorse the third-party websites or services that are linked from the platform.
                </p>
                <h3>9. Termination of Use</h3>
                <p>
                  We reserve the right to terminate or suspend your access to the website at our
                  sole discretion, without prior notice, for any reason, including but not limited
                  to violation of these terms.
                </p>
                <h3>10. Modifications to Terms</h3>
                <p>
                  aiatlas.app reserves the right to modify these terms and conditions at any time.
                  We will notify users of any significant changes by posting the updated terms on
                  the website. Your continued use of the website after changes have been made
                  constitutes your acceptance of the new terms.
                </p>
                <h3>11. Privacy Policy</h3>
                <p>
                  Your use of aiatlas.app is also governed by our Privacy Policy, which outlines
                  how we collect, use, and protect your data.
                </p>
                <h3>12. Governing Law</h3>
                <p>
                  These terms and conditions are governed by and construed in accordance with the
                  laws of USA, without regard to its conflict of law provisions.
                </p>
              </div>)}
        </div>
      </div>);
}

  // Component for displaying the icons in a grid
function IconWall({ icons, onAddClick }) {
  const handleIconClick = async (iconId, currentClicks, recentClicks, lastClickedAt) => {
    const oneHourAgo = new Date().getTime() - 60 * 60 * 1000;

    // Reset recentClicks if the last click was more than an hour ago
    let updatedRecentClicks = recentClicks;
    if (lastClickedAt && lastClickedAt.toMillis() < oneHourAgo) {
      updatedRecentClicks = 0;
    }

    const iconRef = doc(db, 'icons', iconId);
    await updateDoc(iconRef, {
      clicks: currentClicks + 1, recentClicks: updatedRecentClicks + 1, lastClickedAt: new Date(),
    });
  };

  useEffect(() => {
    icons.forEach((icon) => {
      const link = document.createElement('link');
      link.rel = 'prefetch';
      link.href = icon.src.replace('.jpg', '.webp'); // Prefetch image
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    });
  }, [icons]);

  return (<div className="icon-grid">
        <motion.div className="icon add-icon-tile" onClick={onAddClick}>
          <span className="plus-sign">+</span>
        </motion.div>

        {icons.map((icon) => (<ImageWithPlaceholder
                key={icon.id}
                icon={icon}
                handleIconClick={() => handleIconClick(icon.id, icon.clicks, icon.recentClicks
                    || 0, icon.lastClickedAt)}
            />))}
      </div>);
}



export default AICon;
