import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPomvio7_vpUWiBEcpVHzTLRjZo6kevzU",
  authDomain: "aicons-eafde.firebaseapp.com",
  projectId: "aicons-eafde",
  storageBucket: "aicons-eafde.appspot.com",
  messagingSenderId: "279773416736",
  appId: "1:279773416736:web:f34fdd3f1b57751d2b1b45",
  measurementId: "G-3SB73TSRHH"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };