import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

// Bind modal to the root element (required for accessibility)
Modal.setAppElement('#root');

function ImageWithPlaceholder({ icon }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [screenshotUrl, setScreenshotUrl] = useState(''); // Screenshot URL state
  const [loadingScreenshot, setLoadingScreenshot] = useState(true); // Loading state for screenshot
  const [hoverTimer, setHoverTimer] = useState(null); // Timer for 2-second hover

  const { url = '', src = '', name = '', description = '' } = icon;

  // Fetch screenshot URL from Microlink API (or similar service)
  useEffect(() => {
    const fetchScreenshot = async () => {
      if (!url) return; // If no URL, do nothing
      setLoadingScreenshot(true); // Set loading state
      try {
        const response = await fetch(`https://api.microlink.io/?url=${url}&screenshot=true`);
        const data = await response.json();
        // Set screenshot URL if valid
        if (data && data.status === 'success' && data.data.screenshot && data.data.screenshot.url) {
          setScreenshotUrl(data.data.screenshot.url);
        } else {
          setScreenshotUrl(''); // Set empty if no screenshot is available
        }
      } catch (error) {
        console.error('Error fetching screenshot:', error);
        setScreenshotUrl(''); // Handle errors by showing no preview
      } finally {
        setLoadingScreenshot(false); // Stop loading state
      }
    };

    fetchScreenshot();
  }, [url]);

  // Handle mouse enter (start hover)
  const handleMouseEnter = () => {
    if (!hoverTimer) {
      const timer = setTimeout(() => {
        setIsModalOpen(true); // Show the modal after 2 seconds of hover
      }, 3000);
      setHoverTimer(timer);
    }
  };

  // Handle mouse leave (close modal if completely outside both icon and modal)
  const handleMouseLeave = (event) => {
    const target = event.relatedTarget;

    // Ensure target is a valid Node before calling contains
    if (!target || !(target instanceof Node) || !event.currentTarget.contains(target)) {
      // If the mouse leaves the entire container (icon + modal), close the modal
      if (hoverTimer) {
        clearTimeout(hoverTimer); // Clear the hover timer if mouse leaves early
        setHoverTimer(null);
      }
      setIsModalOpen(false); // Close the modal
    }
  };

  // Handle click on the icon or screenshot to open the URL
  const handleClick = (event) => {
    // Prevent event bubbling to avoid clicking on the overlay to open the URL
    event.stopPropagation();
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
      <>
        <div
            className="hover-container"
            onMouseEnter={handleMouseEnter} // Start hover timer
            onMouseLeave={handleMouseLeave} // Close modal when mouse leaves the hover area
            style={{ cursor: 'pointer', position: 'relative' }}
        >
          <div className="icon" onClick={handleClick}> {/* Open the URL when clicking the icon */}
            <img src={src} alt={name} />

            {/* Tooltip for icon description */}
            <div className="tooltip">{description}</div>
          </div>

          {/* Modal for displaying the screenshot preview */}
          <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)} // Close modal on request
              shouldCloseOnOverlayClick={true} // Allow modal to close when clicking outside
              style={{
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)', // Center the modal
                  width: 'auto', // Automatically adjust width based on content
                  maxWidth: '40vw', // Ensure modal doesn’t exceed 90% of viewport width
                  maxHeight: '40vh', // Ensure modal height doesn’t exceed 90% of viewport height
                  overflow: 'hidden', // Prevent scrolling
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black background
                  display: 'flex',
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center', // Center content vertically
                },
                overlay: {
                  '@media (max-width: 768px)': {
                    width: '90vw', // Use 90% of the viewport width on smaller screens
                    maxHeight: '90vh', // Ensure modal fits within the viewport height on mobile
                  },
                },
              }}
          >
            <div className="preview-box">
              {/* Show loading message while fetching the screenshot */}
              {loadingScreenshot && <p>Loading preview...</p>}

              {/* Display the screenshot if available, otherwise show fallback message */}
              {!loadingScreenshot && screenshotUrl ? (
                  <img
                      src={screenshotUrl}
                      alt="Page Preview"
                      style={{
                        maxWidth: '100%', // Ensure image doesn't exceed modal width
                        maxHeight: '90vh', // Ensure image doesn't exceed modal height
                        objectFit: 'contain', // Maintain aspect ratio and prevent distortion
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClick} // Open the URL when the screenshot is clicked
                  />
              ) : (
                  !loadingScreenshot && <p>Preview not available</p>
              )}
            </div>
          </Modal>


        </div>
      </>
  );
}

export default ImageWithPlaceholder;
